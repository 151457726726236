.e-avatar {
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #edebe9;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    color: #605e5c;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: 40px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 40px;
    line-height: 24px;
}

    .e-avatar img {
        height: 100%;
        width: auto;
    }

    .e-avatar.e-avatar-circle {
        border-radius: 50%;
    }

        .e-avatar.e-avatar-circle.e-avatar-large, .e-avatar.e-avatar-circle.e-avatar-xlarge {
            border-radius: 50%;
        }

    .e-avatar.e-avatar-xsmall {
        font-size: 10px;
        height: 24px;
        width: 24px;
        line-height: 16px;
    }

    .e-avatar.e-avatar-small {
        font-size: 12px;
        height: 32px;
        width: 32px;
        line-height: 18px;
    }

    .e-avatar.e-avatar-large {
        font-size: 20px;
        height: 48px;
        width: 48px;
        line-height: 28px;
    }

    .e-avatar.e-avatar-xlarge {
        font-size: 24px;
        height: 56px;
        width: 56px;
        line-height: 28px;
    }

.e-card {
    border-radius: 2px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
    font-size: 15px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 36px;
    min-height: 36px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    vertical-align: middle;
    width: 100%;
}

    .e-card > * {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .e-card > .e-card-header-title {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        font-size: 16px;
        line-height: 1.5;
        padding: 12px;
    }

        .e-card > .e-card-header-title + :not(.e-card-header-title) {
            margin-top: 0;
            padding-top: 0;
        }

    .e-card > .e-card-title {
        font-size: 18px;
        line-height: 1.5;
        padding: 16px;
    }

        .e-card > .e-card-title + :not(.e-card-title) {
            margin-top: 0;
            padding-top: 0;
        }

    .e-card > .e-card-header-title,
    .e-card > .e-card-title {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .e-card .e-card-header-caption {
        line-height: normal;
    }

        .e-card .e-card-header-caption .e-card-header-title {
            font-size: 16px;
        }

        .e-card .e-card-header-caption .e-card-sub-title {
            font-size: 12px;
        }

    .e-card .e-card-stacked {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        overflow: hidden;
    }

    .e-card.e-card-horizontal,
    .e-card .e-card-horizontal {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

        .e-card.e-card-horizontal .e-card-image,
        .e-card.e-card-horizontal img,
        .e-card .e-card-horizontal .e-card-image,
        .e-card .e-card-horizontal img {
            margin: 0;
        }

    .e-card .e-card-horizontal {
        line-height: normal;
        padding: 2px;
    }

        .e-card.e-card-horizontal > * {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
        }

        .e-card.e-card-horizontal .e-card-stacked {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            overflow: hidden;
        }

            .e-card.e-card-horizontal .e-card-stacked > :first-child {
                -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                flex-grow: 1;
            }

    .e-card .e-card-separator {
        display: block;
    }

    .e-card .e-card-corner {
        border-radius: 50%;
    }

    .e-card .e-card-header {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        line-height: normal;
        min-height: 22.5px;
        padding: 16px 16px 0 16px;
        width: inherit;
    }

        .e-card .e-card-header .e-card-content {
            padding-left: 0;
            padding-right: 0;
        }

        .e-card .e-card-header .e-card-actions {
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
        }

        .e-card .e-card-header .e-card-header-image {
            -ms-flex-item-align: center;
            align-self: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
        }

        .e-card .e-card-header .e-card-header-caption {
            -ms-flex-item-align: center;
            align-self: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            overflow: hidden;
            padding: 0 0 0 12px;
        }

            .e-card .e-card-header .e-card-header-caption:first-child {
                padding: 0;
            }

            .e-card .e-card-header .e-card-header-caption .e-card-header-title,
            .e-card .e-card-header .e-card-header-caption .e-card-sub-title {
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .e-card .e-card-header .e-card-header-caption .e-card-header-title {
                font-size: 16px;
                font-weight: normal;
                line-height: 1.5;
            }

            .e-card .e-card-header .e-card-header-caption .e-card-sub-title {
                font-size: 12px;
                font-weight: normal;
                line-height: 1.5;
                padding: 4px 0 0;
            }

        .e-card .e-card-header .e-card-header-image {
            background-repeat: no-repeat;
            background-size: cover;
            height: 30px;
            width: 30px;
        }

        .e-card .e-card-header .e-card-corner {
            border-radius: 50%;
        }

        .e-card .e-card-header + .e-card-header {
            padding: 16px;
        }

    .e-card .e-card-image {
        background-size: cover;
        min-height: 112.5px;
        position: relative;
        width: 100%;
    }

        .e-card .e-card-image .e-card-title {
            bottom: 0;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            font-size: 16px;
            line-height: 30px;
            min-height: 30px;
            overflow: hidden;
            padding: 8px 16px;
            position: absolute;
            text-overflow: ellipsis;
            width: inherit;
        }

            .e-card .e-card-image .e-card-title.e-card-top-left, .e-card .e-card-image .e-card-title.e-card-top-right {
                bottom: auto;
                top: 0;
            }

            .e-card .e-card-image .e-card-title.e-card-top-right {
                text-align: right;
            }

            .e-card .e-card-image .e-card-title.e-card-bottom-right {
                text-align: right;
            }

    .e-card .e-card-actions {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: inline-block;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        padding: 16px;
    }

        .e-card .e-card-actions.e-card-vertical {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            padding: 12px;
        }

            .e-card .e-card-actions.e-card-vertical .e-card-btn,
            .e-card .e-card-actions.e-card-vertical a {
                -ms-flex-item-align: initial;
                align-self: initial;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                display: inline-block;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                margin: 0 0 6px 0;
                text-align: center;
                width: 100%;
            }

                .e-card .e-card-actions.e-card-vertical .e-card-btn:last-child,
                .e-card .e-card-actions.e-card-vertical a:last-child {
                    margin-bottom: 0;
                }

        .e-card .e-card-actions button,
        .e-card .e-card-actions a {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-item-align: center;
            align-self: center;
            cursor: pointer;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            overflow: hidden;
            vertical-align: middle;
            white-space: nowrap;
        }

        .e-card .e-card-actions .e-card-btn span,
        .e-card .e-card-actions .e-card-btn span.e-icons {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            font-size: 18px;
            height: 24px;
            width: 24px;
        }

            .e-card .e-card-actions .e-card-btn span::before,
            .e-card .e-card-actions .e-card-btn span.e-icons::before {
                -ms-flex-item-align: center;
                align-self: center;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                width: inherit;
            }

        .e-card .e-card-actions .e-card-btn > * {
            overflow: hidden;
            text-overflow: ellipsis;
            width: inherit;
        }

        .e-card .e-card-actions .e-card-btn,
        .e-card .e-card-actions a {
            border-radius: 2px;
        }

        .e-card .e-card-actions .e-card-btn,
        .e-card .e-card-actions a {
            font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
            font-size: 14px;
            line-height: 30px;
            margin: 0 0 0 6px;
            min-height: 30px;
            padding: 0 6px;
            text-decoration: none;
            text-transform: none;
        }

            .e-card .e-card-actions .e-card-btn:first-child,
            .e-card .e-card-actions a:first-child {
                margin-left: 0;
            }

    .e-card .e-card-content {
        font-size: 14px;
        line-height: 1.5;
        padding: 16px;
        word-wrap: break-word;
    }

        .e-card .e-card-content + :not(.e-card-content),
        .e-card .e-card-content + .e-card-actions.e-card-vertical {
            margin-top: 0;
            padding-top: 0;
        }

        .e-card .e-card-content + .e-card-header {
            padding: 16px;
        }

        .e-card .e-card-content,
        .e-card .e-card-content p {
            line-height: 1.5;
            overflow: hidden;
            text-overflow: ellipsis;
        }

/* stylelint-disable property-no-vendor-prefix */
.e-card {
    -webkit-tap-highlight-color: var(--theme-indicator-bg-color-active);
    background: #fff;
    border: 0 solid #edebe9;
    -webkit-box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
    box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
    color: #201f1e;
    outline: none;
}

    .e-card:hover {
        background-color: #fff;
        border-color: #c8c6c4;
    }

    .e-card:focus {
        background-color: #fff;
        border-color: #edebe9;
    }

    .e-card:active {
        background-color: #fff;
        border-color: var(--theme-indicator-bg-color-active);
    }

    .e-card .e-card-separator {
        border-bottom: 1px solid #edebe9;
    }

    .e-card .e-card-header-caption .e-card-header-title {
        color: #201f1e;
    }

    .e-card .e-card-header-caption .e-card-sub-title {
        color: #3b3a39;
    }

    .e-card .e-card-image .e-card-title {
        background-color: rgba(0, 0, 0, 0.4);
        color: #fff;
    }

    .e-card .e-card-actions .e-card-btn span,
    .e-card .e-card-actions .e-card-btn span.e-icons {
        color: var(--theme-indicator-bg-color-active);
    }

    .e-card .e-card-actions .e-card-btn,
    .e-card .e-card-actions a {
        background-color: transparent;
        border: 1px solid var(--theme-indicator-bg-color-active);
        color: var(--theme-indicator-bg-color-active);
        outline: 0;
    }

        .e-card .e-card-actions .e-card-btn:hover,
        .e-card .e-card-actions a:hover {
            background-color: var(--theme-indicator-bg-color-active);
            border: 1px solid #8a8886;
            color: #fff;
        }

        .e-card .e-card-actions .e-card-btn:focus,
        .e-card .e-card-actions a:focus {
            background-color: #f3f2f1;
            border: 1px solid;
            color: #323130;
        }

        .e-card .e-card-actions .e-card-btn:active,
        .e-card .e-card-actions a:active {
            background-color: #edebe9;
            border: 1px solid #8a8886;
            color: #323130;
        }

    .e-card .e-card-content {
        color: #323130;
    }

.e-card-hover:hover {
    -webkit-box-shadow: 0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108);
    box-shadow: 0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108);
}

.e-card-active:active {
    border: 1px solid #d2d0ce;
    -webkit-box-shadow: 0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108);
    box-shadow: 0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108);
}

.e-bigger .e-card,
.e-bigger.e-card {
    line-height: 48px;
    min-height: 48px;
}

    .e-bigger .e-card > .e-card-header-title,
    .e-bigger.e-card > .e-card-header-title {
        line-height: 1.5;
        padding: 20px 20px 0 20px;
    }

        .e-bigger .e-card > .e-card-header-title + :not(.e-card-header-title),
        .e-bigger.e-card > .e-card-header-title + :not(.e-card-header-title) {
            margin-top: 0;
            padding-top: 0;
        }

    .e-bigger .e-card > .e-card-title,
    .e-bigger.e-card > .e-card-title {
        line-height: 1.5;
        padding: 20px;
    }

        .e-bigger .e-card > .e-card-title + :not(.e-card-title),
        .e-bigger.e-card > .e-card-title + :not(.e-card-title) {
            margin-top: 0;
            padding-top: 0;
        }

    .e-bigger .e-card.e-card-horizontal .e-card-image,
    .e-bigger .e-card.e-card-horizontal img,
    .e-bigger .e-card .e-card-horizontal .e-card-image,
    .e-bigger .e-card .e-card-horizontal img,
    .e-bigger.e-card.e-card-horizontal .e-card-image,
    .e-bigger.e-card.e-card-horizontal img,
    .e-bigger.e-card .e-card-horizontal .e-card-image,
    .e-bigger.e-card .e-card-horizontal img {
        margin: 0;
    }

    .e-bigger .e-card .e-card-header,
    .e-bigger.e-card .e-card-header {
        min-height: 30px;
        padding: 20px 20px 0 20px;
    }

        .e-bigger .e-card .e-card-header .e-card-content,
        .e-bigger.e-card .e-card-header .e-card-content {
            padding-left: 0;
            padding-right: 0;
        }

        .e-bigger .e-card .e-card-header .e-card-header-caption,
        .e-bigger.e-card .e-card-header .e-card-header-caption {
            padding: 0 0 0 16px;
        }

            .e-bigger .e-card .e-card-header .e-card-header-caption:first-child,
            .e-bigger.e-card .e-card-header .e-card-header-caption:first-child {
                padding: 0;
            }

            .e-bigger .e-card .e-card-header .e-card-header-caption .e-card-header-title,
            .e-bigger.e-card .e-card-header .e-card-header-caption .e-card-header-title {
                font-size: 18px;
                line-height: 1.5;
            }

            .e-bigger .e-card .e-card-header .e-card-header-caption .e-card-sub-title,
            .e-bigger.e-card .e-card-header .e-card-header-caption .e-card-sub-title {
                font-size: 14px;
                line-height: 1.5;
                padding: 8px 0 0;
            }

        .e-bigger .e-card .e-card-header .e-card-header-image,
        .e-bigger.e-card .e-card-header .e-card-header-image {
            background-size: cover;
            height: 40px;
            width: 40px;
        }

        .e-bigger .e-card .e-card-header + .e-card-header,
        .e-bigger.e-card .e-card-header + .e-card-header {
            padding: 20px;
        }

    .e-bigger .e-card .e-card-image,
    .e-bigger.e-card .e-card-image {
        min-height: 150px;
    }

        .e-bigger .e-card .e-card-image .e-card-title,
        .e-bigger.e-card .e-card-image .e-card-title {
            font-size: 18px;
            line-height: 1.25;
            min-height: 40px;
            padding: 12px 20px;
        }

    .e-bigger .e-card .e-card-actions,
    .e-bigger.e-card .e-card-actions {
        padding: 20px;
    }

        .e-bigger .e-card .e-card-actions.e-card-vertical,
        .e-bigger.e-card .e-card-actions.e-card-vertical {
            padding: 16px;
        }

            .e-bigger .e-card .e-card-actions.e-card-vertical .e-card-btn,
            .e-bigger .e-card .e-card-actions.e-card-vertical a,
            .e-bigger.e-card .e-card-actions.e-card-vertical .e-card-btn,
            .e-bigger.e-card .e-card-actions.e-card-vertical a {
                margin: 0 0 7px 0;
            }

                .e-bigger .e-card .e-card-actions.e-card-vertical .e-card-btn:last-child,
                .e-bigger .e-card .e-card-actions.e-card-vertical a:last-child,
                .e-bigger.e-card .e-card-actions.e-card-vertical .e-card-btn:last-child,
                .e-bigger.e-card .e-card-actions.e-card-vertical a:last-child {
                    margin-bottom: 0;
                }

        .e-bigger .e-card .e-card-actions .e-card-btn span,
        .e-bigger .e-card .e-card-actions .e-card-btn span.e-icons,
        .e-bigger.e-card .e-card-actions .e-card-btn span,
        .e-bigger.e-card .e-card-actions .e-card-btn span.e-icons {
            height: 24px;
            width: 24px;
        }

        .e-bigger .e-card .e-card-actions .e-card-btn,
        .e-bigger .e-card .e-card-actions a,
        .e-bigger.e-card .e-card-actions .e-card-btn,
        .e-bigger.e-card .e-card-actions a {
            line-height: 38px;
            margin: 0 0 0 13px;
            min-height: 38px;
            padding: 0 6px;
        }

            .e-bigger .e-card .e-card-actions .e-card-btn:first-child,
            .e-bigger .e-card .e-card-actions a:first-child,
            .e-bigger.e-card .e-card-actions .e-card-btn:first-child,
            .e-bigger.e-card .e-card-actions a:first-child {
                margin-left: 0;
            }

    .e-bigger .e-card .e-card-content,
    .e-bigger.e-card .e-card-content {
        padding: 20px;
    }

        .e-bigger .e-card .e-card-content + :not(.e-card-content),
        .e-bigger .e-card .e-card-content + .e-card-actions.e-card-vertical,
        .e-bigger.e-card .e-card-content + :not(.e-card-content),
        .e-bigger.e-card .e-card-content + .e-card-actions.e-card-vertical {
            margin-top: 0;
            padding-top: 0;
        }

        .e-bigger .e-card .e-card-content + .e-card-header,
        .e-bigger.e-card .e-card-content + .e-card-header {
            padding: 20px;
        }

.e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler::before {
    content: "\e7e3";
    font-family: "e-icons";
    font-size: 14px;
}

.e-splitter .e-split-bar.e-split-bar-vertical .e-resize-handler::before {
    content: "\e7fd";
    font-family: "e-icons";
    font-size: 14px;
}

.e-splitter.e-splitter-horizontal, .e-splitter.e-splitter-vertical {
    border-width: 1px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    position: relative;
    -ms-touch-action: none;
    touch-action: none;
    width: 100%;
}

    .e-splitter.e-splitter-horizontal .e-pane, .e-splitter.e-splitter-vertical .e-pane {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
        font-size: 14px;
        font-weight: 400;
    }

        .e-splitter.e-splitter-horizontal .e-pane.e-scrollable, .e-splitter.e-splitter-vertical .e-pane.e-scrollable {
            overflow: auto;
        }

        .e-splitter.e-splitter-horizontal .e-pane.e-static-pane, .e-splitter.e-splitter-vertical .e-pane.e-static-pane {
            -webkit-box-flex: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            -ms-flex-negative: 0;
            flex-shrink: 0;
        }

        .e-splitter.e-splitter-horizontal .e-pane.e-pane-horizontal, .e-splitter.e-splitter-vertical .e-pane.e-pane-horizontal {
            overflow: auto;
        }

            .e-splitter.e-splitter-horizontal .e-pane.e-pane-horizontal.e-pane-hidden, .e-splitter.e-splitter-vertical .e-pane.e-pane-horizontal.e-pane-hidden {
                -ms-flex-preferred-size: 0 !important;
                flex-basis: 0 !important; /* stylelint-disable-line declaration-no-important */
                overflow: hidden !important; /* stylelint-disable-line declaration-no-important */
            }

.e-splitter.e-splitter-horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

    .e-splitter.e-splitter-horizontal.e-pane.e-scrollable {
        overflow: hidden;
    }

    .e-splitter.e-splitter-horizontal.e-rtl {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }

        .e-splitter.e-splitter-horizontal.e-rtl .e-split-bar.e-split-bar-horizontal {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: reverse;
            -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
        }

            .e-splitter.e-splitter-horizontal.e-rtl .e-split-bar.e-split-bar-horizontal .e-navigate-arrow:not(.e-arrow-right)::before,
            .e-splitter.e-splitter-horizontal.e-rtl .e-split-bar.e-split-bar-horizontal .e-navigate-arrow:not(.e-arrow-right)::after {
                right: auto;
            }

.e-splitter.e-splitter-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

    .e-splitter.e-splitter-vertical.e-pane.e-scrollable {
        overflow: hidden;
    }

    .e-splitter.e-splitter-vertical .e-pane-vertical {
        overflow: auto;
    }

        .e-splitter.e-splitter-vertical .e-pane-vertical.e-pane-hidden {
            -ms-flex-preferred-size: 0 !important;
            flex-basis: 0 !important; /* stylelint-disable-line declaration-no-important */
            overflow: hidden !important; /* stylelint-disable-line declaration-no-important */
        }

    .e-splitter.e-splitter-vertical.e-rtl .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::before,
    .e-splitter.e-splitter-vertical.e-rtl .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::after {
        right: auto;
    }

    .e-splitter.e-splitter-vertical.e-rtl .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down) {
        border-radius: 50%;
        bottom: 20px;
        right: 22px;
    }

    .e-splitter.e-splitter-vertical.e-rtl .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down {
        border-radius: 50%;
        left: 22px;
        right: auto;
        top: 20px;
    }

        .e-splitter.e-splitter-vertical.e-rtl .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down::after {
            right: auto;
        }

.e-splitter .e-split-bar.e-split-bar-horizontal {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-width: 1px;
    z-index: 15;
}

    .e-splitter .e-split-bar.e-split-bar-horizontal .e-icon-hidden {
        visibility: hidden;
    }

    .e-splitter .e-split-bar.e-split-bar-horizontal.e-resizable-split-bar {
        cursor: col-resize;
    }

        .e-splitter .e-split-bar.e-split-bar-horizontal.e-resizable-split-bar::after {
            content: "";
            cursor: col-resize;
            display: block;
            height: 100%;
            position: absolute;
            width: 16px;
            z-index: 10;
        }

    .e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 18px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        position: relative;
        width: 1px;
    }

        .e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler.e-hide-handler {
            visibility: hidden;
        }

    .e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow:not(.e-arrow-right)::before,
    .e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow:not(.e-arrow-right)::after {
        left: 8px;
        right: 3px;
    }

    .e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow {
        -o-border-image: none;
        border-image: none;
        border-width: 1px;
        cursor: pointer;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 26px;
        padding: 1px 12px;
        position: relative;
    }

        .e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow::before, .e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow::after {
            border-radius: 16px;
            content: "";
            height: 8px;
            position: absolute;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            width: 2px;
        }

        .e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow::before {
            top: 5px;
            -webkit-transform-origin: 1px 7px 0;
            transform-origin: 1px 7px 0;
        }

        .e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow::after {
            top: 11px;
            -webkit-transform-origin: 1px 1px 0;
            transform-origin: 1px 1px 0;
        }

        .e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow:not(.e-arrow-right) {
            border-radius: 50%;
            right: 5px;
        }

        .e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow.e-arrow-right {
            border-radius: 50%;
            left: 5px;
        }

            .e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow.e-arrow-right::before, .e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow.e-arrow-right::after {
                right: 8px;
            }

    .e-splitter .e-split-bar.e-split-bar-horizontal.e-last-bar:not(.e-resizable-split-bar) {
        margin: 0 3px;
    }

    .e-splitter .e-split-bar.e-split-bar-horizontal:not(.e-resizable-split-bar) {
        margin: 0 0 0 3px;
    }

.e-splitter .e-split-bar.e-split-bar-vertical {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-left: 1px solid #edebe9;
    border-right: 1px solid #edebe9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 1px;
}

    .e-splitter .e-split-bar.e-split-bar-vertical .e-icon-hidden {
        visibility: hidden;
    }

    .e-splitter .e-split-bar.e-split-bar-vertical.e-resizable-split-bar {
        cursor: row-resize;
    }

        .e-splitter .e-split-bar.e-split-bar-vertical.e-resizable-split-bar::after {
            content: "";
            cursor: row-resize;
            display: block;
            height: 16px;
            position: absolute;
            width: 100%;
            z-index: 12;
        }

    .e-splitter .e-split-bar.e-split-bar-vertical .e-resize-handler {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 1px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 18px;
    }

        .e-splitter .e-split-bar.e-split-bar-vertical .e-resize-handler.e-hide-handler {
            visibility: hidden;
        }

    .e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::before,
    .e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::after {
        left: 10px;
    }

    .e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow {
        -o-border-image: none;
        border-image: none;
        border-width: 0;
        cursor: pointer;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 26px;
        padding: 10px 3px 10px 18px;
        position: relative;
        width: 26px;
    }

        .e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow::before, .e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow::after {
            border-radius: 16px;
            content: "";
            height: 8px;
            position: absolute;
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
            width: 2px;
        }

        .e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::before {
            top: 3px;
            -webkit-transform-origin: 2px 7px 0;
            transform-origin: 2px 7px 0;
        }

        .e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::after {
            left: 11px;
            top: 8px;
            -webkit-transform-origin: 1px 1px 0;
            transform-origin: 1px 1px 0;
        }

        .e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down::before {
            left: 12px;
            top: 8px;
            -webkit-transform-origin: 1px 6px 0;
            transform-origin: 1px 6px 0;
        }

        .e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down::after {
            left: 10px;
            top: 12px;
            -webkit-transform-origin: 1px 2px 0;
            transform-origin: 1px 2px 0;
        }

        .e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down) {
            border-radius: 50%;
            bottom: 20px;
            left: 22px;
        }

        .e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down {
            border-radius: 50%;
            right: 22px;
            top: 20px;
        }

            .e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down::before, .e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down::after {
                right: 10px;
            }

    .e-splitter .e-split-bar.e-split-bar-vertical.e-last-bar:not(.e-resizable-split-bar) {
        margin: 3px 0;
    }

    .e-splitter .e-split-bar.e-split-bar-vertical:not(.e-resizable-split-bar) {
        margin: 3px 0 0;
    }

.e-rtl .e-splitter.e-splitter-horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.e-content-placeholder.e-splitter.e-placeholder-splitter {
    background-size: 100px 110px;
    min-height: 110px;
}

.e-splitter.e-ie .e-navigate-arrow.e-arrow-left {
    margin-left: -26px;
}

.e-splitter.e-ie .e-split-bar-horizontal .e-resize-handler {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.e-splitter.e-splitter-horizontal, .e-splitter.e-splitter-vertical {
    border: solid 1px #edebe9;
}

    .e-splitter.e-splitter-horizontal .e-pane, .e-splitter.e-splitter-vertical .e-pane {
        color: #201f1e;
    }

        .e-splitter.e-splitter-horizontal .e-pane.e-splitter.e-splitter-horizontal, .e-splitter.e-splitter-horizontal .e-pane.e-splitter.e-splitter-vertical, .e-splitter.e-splitter-vertical .e-pane.e-splitter.e-splitter-horizontal, .e-splitter.e-splitter-vertical .e-pane.e-splitter.e-splitter-vertical {
            border: transparent;
        }

.e-splitter .e-split-bar.e-split-bar-horizontal {
    background: #edebe9;
    border-left: 1px solid #edebe9;
    border-right: 1px solid #edebe9;
}

    .e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler {
        background: #fff;
        color: #605e5c;
        z-index: 10;
    }

    .e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow:not(.e-icon-hidden) {
        background-color: transparent;
        border-color: transparent;
        color: transparent;
        -webkit-transition: background-color 500ms ease-out;
        transition: background-color 500ms ease-out;
    }

        .e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow:not(.e-icon-hidden)::before, .e-splitter .e-split-bar.e-split-bar-horizontal .e-navigate-arrow:not(.e-icon-hidden)::after {
            background-color: var(--theme-indicator-bg-color-active);
            opacity: 0;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
            transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        }

    .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active {
        background: var(--theme-indicator-bg-color-active);
        border-left: 1px solid var(--theme-indicator-bg-color-active);
        border-right: 1px solid var(--theme-indicator-bg-color-active);
    }

        .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-resize-handler, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-resize-handler {
            background: #fff;
            color: var(--theme-indicator-bg-color-active);
        }

        .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-navigate-arrow.e-arrow-left, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-navigate-arrow.e-arrow-right, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-navigate-arrow.e-arrow-left, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-navigate-arrow.e-arrow-right {
            background-color: transparent;
            border-color: var(--theme-indicator-bg-color-active);
            border-width: 1px;
            opacity: 0.9;
        }

        .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover.e-split-bar-hover .e-navigate-arrow::before, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover.e-split-bar-active .e-navigate-arrow::before, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active.e-split-bar-hover .e-navigate-arrow::before, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active.e-split-bar-active .e-navigate-arrow::before {
            background-color: var(--theme-indicator-bg-color-active);
            opacity: 1;
            -webkit-transform: rotate(40deg);
            transform: rotate(40deg);
        }

        .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover.e-split-bar-hover .e-navigate-arrow::after, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover.e-split-bar-active .e-navigate-arrow::after, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active.e-split-bar-hover .e-navigate-arrow::after, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active.e-split-bar-active .e-navigate-arrow::after {
            background-color: var(--theme-indicator-bg-color-active);
            opacity: 1;
            -webkit-transform: rotate(-40deg);
            transform: rotate(-40deg);
        }

        .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-navigate-arrow.e-arrow-right::before, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-navigate-arrow.e-arrow-right::before {
            background-color: var(--theme-indicator-bg-color-active);
            opacity: 1;
            -webkit-transform: rotate(-40deg);
            transform: rotate(-40deg);
        }

        .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover .e-navigate-arrow.e-arrow-right::after, .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-navigate-arrow.e-arrow-right::after {
            background-color: var(--theme-indicator-bg-color-active);
            opacity: 1;
            -webkit-transform: rotate(40deg);
            transform: rotate(40deg);
        }

    .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active {
        background: var(--theme-indicator-bg-color-active);
    }

        .e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active .e-resize-handler {
            background: #fff;
            color: var(--theme-indicator-bg-color-active);
        }

.e-splitter .e-split-bar.e-split-bar-vertical {
    background: #edebe9;
}

    .e-splitter .e-split-bar.e-split-bar-vertical .e-resize-handler {
        background: #fff;
        color: #605e5c;
        z-index: 12;
    }

    .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover, .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active {
        background: var(--theme-indicator-bg-color-active);
        border-left: 1px solid var(--theme-indicator-bg-color-active);
        border-right: 1px solid var(--theme-indicator-bg-color-active);
    }

        .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-up, .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-down, .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-up, .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-down {
            background-color: transparent;
            border-color: var(--theme-indicator-bg-color-active);
            border-width: 1px;
            opacity: 0.9;
        }

        .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-resize-handler, .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-resize-handler {
            background: #fff;
            color: var(--theme-indicator-bg-color-active);
        }

        .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-up::before, .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-up::before {
            background-color: var(--theme-indicator-bg-color-active);
            opacity: 1;
            -webkit-transform: rotate(125deg);
            transform: rotate(125deg);
        }

        .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-up::after, .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-up::after {
            background-color: var(--theme-indicator-bg-color-active);
            opacity: 1;
            -webkit-transform: rotate(50deg);
            transform: rotate(50deg);
        }

        .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-down::before, .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-down::before {
            background-color: var(--theme-indicator-bg-color-active);
            opacity: 1;
            -webkit-transform: rotate(55deg);
            transform: rotate(55deg);
        }

        .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-hover .e-navigate-arrow.e-arrow-down::after, .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-navigate-arrow.e-arrow-down::after {
            background-color: var(--theme-indicator-bg-color-active);
            opacity: 1;
            -webkit-transform: rotate(125deg);
            transform: rotate(125deg);
        }

    .e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow {
        background-color: transparent;
        border-color: transparent;
        color: transparent;
        -webkit-transition: background-color 300ms ease-out;
        transition: background-color 300ms ease-out;
    }

        .e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow::before, .e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow::after {
            background-color: var(--theme-indicator-bg-color-active);
            opacity: 0;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
            transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
        }

    .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active {
        background: var(--theme-indicator-bg-color-active);
    }

        .e-splitter .e-split-bar.e-split-bar-vertical.e-split-bar-active .e-resize-handler {
            background: #fff;
            color: var(--theme-indicator-bg-color-active);
        }

.e-splitter.e-disabled {
    opacity: 0.4;
    pointer-events: none;
}

/* stylelint-disable-line no-empty-source */
.e-bigger.e-splitter .e-pane {
    font-size: 16px;
}

.e-bigger.e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler {
    height: 20px;
}

.e-bigger.e-splitter .e-split-bar.e-split-bar-vertical .e-resize-handler {
    width: 20px;
}

.e-bigger.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::before,
.e-bigger.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow:not(.e-arrow-down)::after {
    margin-top: -2px;
}

.e-bigger.e-splitter .e-split-bar.e-split-bar-vertical .e-navigate-arrow.e-arrow-down::after {
    margin-top: 2px;
}

.e-bigger .e-splitter .e-pane {
    font-size: 16px;
}

.e-bigger .e-splitter .e-split-bar.e-split-bar-horizontal .e-resize-handler {
    height: 20px;
}

.e-bigger .e-splitter .e-split-bar.e-split-bar-vertical .e-resize-handler {
    width: 20px;
}

.e-bigger .e-content-placeholder.e-splitter.e-placeholder-splitter,
.e-bigger.e-content-placeholder.e-splitter.e-placeholder-splitter {
    background-size: 100px 110px;
    min-height: 110px;
}

.e-bigger .e-splitter .e-split-bar .e-resize-handler::before {
    font-size: 16px;
}

.e-bigger.e-splitter .e-split-bar .e-resize-handler::before {
    font-size: 16px;
}

/*! component's theme wise override tailwind-definitions and variables */
/* stylelint-disable */
.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-east, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-east {
    height: 100%;
    padding: 20px 0;
    right: 1px;
    top: 0;
    width: 12px;
}

.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-west, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-west {
    height: 100%;
    left: 0;
    padding: 20px 0;
    top: 0;
    width: 12px;
}

.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-north, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-north {
    height: 12px;
    padding: 0 20px;
    top: 1px;
    width: 100%;
}

.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-south, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-south {
    bottom: 1px;
    height: 12px;
    padding: 0 20px;
    width: 100%;
}

.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-south-east, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-south-east {
    bottom: 0;
    right: 1px;
    z-index: 10;
}

.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-north-west, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-north-west {
    left: 2px;
    top: 2px;
    z-index: 10;
}

.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-north-east, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-north-east {
    right: 2px;
    top: 2px;
    z-index: 10;
}

.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-south-west, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-south-west {
    bottom: 1px;
    left: 1px;
    z-index: 10;
}

.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-south-east::before, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-south-east::before {
    bottom: 4px;
    content: "\e761";
    font-size: 12px;
    position: absolute;
    right: 4px;
}

.e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-south-west::before, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-south-west::before {
    bottom: 4px;
    content: "\e761";
    font-size: 12px;
    left: 4px;
    position: absolute;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.e-dashboardlayout.e-control .e-dashboard-gridline-table {
    background: #f3f2f1;
    border-collapse: collapse;
    height: 100%;
    width: 100%;
}

    .e-dashboardlayout.e-control .e-dashboard-gridline-table td.e-dashboard-gridline {
        border: 1px dotted #c8c6c4;
        position: absolute;
    }

.e-dashboardlayout.e-control.e-responsive {
    width: 100% !important;
}

.e-dashboardlayout.e-control.e-prevent {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.e-dashboardlayout.e-control .e-panel.e-ssr {
    position: relative;
}

.e-dashboardlayout.e-control .e-panel {
    border: 1px solid #fff;
    height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
    border-radius: 2px;
}

    .e-dashboardlayout.e-control .e-panel:hover {
        border: 1px solid #c8c6c4;
        -webkit-box-shadow: 0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108);
        box-shadow: 0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108);
    }

        .e-dashboardlayout.e-control .e-panel:hover .e-panel-container .e-resize.e-dl-icon {
            display: block;
        }

    .e-dashboardlayout.e-control .e-panel:active {
        border: 1px solid var(--theme-indicator-bg-color-active);
    }

    .e-dashboardlayout.e-control .e-panel .e-panel-container {
        height: 100%;
        width: 100%;
    }

        .e-dashboardlayout.e-control .e-panel .e-panel-container .e-panel-content .e-blazor-template {
            height: inherit;
            width: inherit;
        }

        .e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-dl-icon {
            display: none;
        }

    .e-dashboardlayout.e-control .e-panel.e-panel-transition {
        -webkit-transition: top 0.5s, left 0.5s;
        transition: top 0.5s, left 0.5s;
    }

    .e-dashboardlayout.e-control .e-panel .e-panel-header {
        border-bottom: 1px solid #edebe9;
        color: #201f1e;
        font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
        font-size: 16px;
        font-weight: 600;
        height: 56px;
        padding: 16px 0 16px 16px;
    }

        .e-dashboardlayout.e-control .e-panel .e-panel-header div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .e-dashboardlayout.e-control .e-panel .e-panel-header .e-header-content {
            display: inline-block;
        }

        .e-dashboardlayout.e-control .e-panel .e-panel-header .e-header-template {
            float: right;
        }

    .e-dashboardlayout.e-control .e-panel .e-resize.e-single, .e-dashboardlayout.e-control .e-panel .e-resize.e-double {
        position: absolute;
        -ms-touch-action: none;
        touch-action: none;
    }

        .e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-east, .e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-west, .e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-north, .e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-south, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-east, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-west, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-north, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-south {
            border: none;
        }

            .e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-east:hover, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-east:hover {
                cursor: e-resize;
            }

            .e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-west:hover, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-west:hover {
                cursor: w-resize;
            }

            .e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-north:hover, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-north:hover {
                cursor: n-resize;
            }

            .e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-south:hover, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-south:hover {
                cursor: s-resize;
            }

        .e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-north-west:hover, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-north-west:hover {
            cursor: nw-resize;
        }

        .e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-north-east:hover, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-north-east:hover {
            cursor: ne-resize;
        }

        .e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-south-west:hover, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-south-west:hover {
            cursor: sw-resize;
        }

        .e-dashboardlayout.e-control .e-panel .e-resize.e-single.e-south-east:hover, .e-dashboardlayout.e-control .e-panel .e-resize.e-double.e-south-east:hover {
            cursor: se-resize;
        }

        .e-dashboardlayout.e-control .e-panel .e-resize.e-single::before, .e-dashboardlayout.e-control .e-panel .e-resize.e-double::before {
            font-family: "e-icons";
            position: absolute;
        }

    .e-dashboardlayout.e-control .e-panel.e-dragging, .e-dashboardlayout.e-control .e-panel.e-item-moving {
        cursor: move;
        z-index: 1111 !important;
    }

    .e-dashboardlayout.e-control .e-panel.e-rtl .e-panel-header .e-header-template {
        float: left;
    }

.e-dashboardlayout.e-control .e-drag:hover {
    cursor: move;
}

.e-dashboardlayout.e-control .e-drag-restrict.e-drag:hover,
.e-dashboardlayout.e-control .e-drag-restrict .e-drag:hover {
    cursor: default;
}

.e-dashboardlayout.e-control .e-holder {
    background: #deecf9;
    border: 1px var(--theme-indicator-bg-color-active) dashed;
    border-radius: 2px;
    position: absolute;
    border-radius: 2px;
}

    .e-dashboardlayout.e-control .e-holder.e-holder-transition {
        -webkit-transition: top 0.3s, left 0.3s;
        transition: top 0.3s, left 0.3s;
    }

.e-content-placeholder.e-dashboardlayout.e-placeholder-dashboardlayout {
    height: 100%;
    width: 100%;
}

.e-dashboardlayout.e-control {
    display: block;
    position: relative;
}

    .e-dashboardlayout.e-control .e-panel {
        background: #fff;
        -webkit-box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
        box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: absolute;
    }

        .e-dashboardlayout.e-control .e-panel .e-panel-container .e-panel-header {
            color: #201f1e;
        }

        .e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-single {
            background: none;
        }

        .e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double {
            color: #a19f9d;
            font-size: 7px;
            height: 7px;
            width: 7px;
        }

            .e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-south-east-double {
                bottom: 2px;
                right: 2px;
            }

            .e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-south-west-double {
                bottom: 2px;
                left: 2px;
            }

            .e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-north-west-double {
                left: 2px;
                top: 2px;
            }

            .e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-north-east-double {
                right: 2px;
                top: 2px;
            }

            .e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-north-east-shrink {
                right: 2px;
                top: 2px;
            }

            .e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-north-west-shrink {
                left: 2px;
                top: 2px;
            }

            .e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-south-west-shrink {
                bottom: 2px;
                left: 2px;
            }

            .e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-south-east-shrink {
                bottom: 2px;
                right: 2px;
            }

            .e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-north-east-expand {
                right: 2px;
                top: 2px;
            }

            .e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-north-west-expand {
                left: 2px;
                top: 2px;
            }

            .e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-south-west-expand {
                bottom: 2px;
                left: 2px;
            }

            .e-dashboardlayout.e-control .e-panel .e-panel-container .e-resize.e-double.e-south-east-expand {
                bottom: 2px;
                right: 2px;
            }

        .e-dashboardlayout.e-control .e-panel:active {
            background: #faf9f8;
        }

.e-bigger .e-dashboardlayout.e-control .e-panel .e-panel-header {
    font-size: 18px;
    font-weight: 600;
    height: 68px;
    padding: 20px 0 20px 20px;
}

.e-bigger .e-panel-header {
    color: #201f1e;
}

.e-bigger .e-panel-header {
    font-size: 18px;
    font-weight: 600;
    height: 68px;
    padding: 20px 0 20px 20px;
}

.e-timeline {
    --dot-size: 16px;
    --dot-outer-space: 0;
    --dot-border: 1px;
    --connector-size: 1px;
    --dot-radius: 50%;
    height: inherit;
    width: 100%;
}

    .e-timeline [class^="e-dot "]::before,
    .e-timeline .e-dot-item,
    .e-timeline .e-dot {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .e-timeline [class^="e-dot "]::before {
        min-width: 32px;
        min-height: 32px;
        border-radius: var(--dot-radius);
    }

    .e-timeline .e-timeline-items {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        list-style: none;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        padding: 0;
        width: inherit;
        height: inherit;
    }

    .e-timeline .e-timeline-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        position: relative;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        width: inherit;
        height: inherit;
    }

        .e-timeline .e-timeline-item.e-connector::after {
            top: 0;
            bottom: 0;
            left: calc(50% - var(--connector-size));
            right: auto;
            content: "";
            position: absolute;
            z-index: 999;
            border-width: var(--connector-size);
            border-style: solid;
        }

        .e-timeline .e-timeline-item.e-connector.e-item-template::after {
            content: unset;
        }

    .e-timeline .e-dot-item {
        position: relative;
        -webkit-box-flex: 0;
        -ms-flex: 0 1 calc(var(--dot-size) * 3);
        flex: 0 1 calc(var(--dot-size) * 3);
        z-index: 1000;
    }

    .e-timeline .e-dot {
        min-width: var(--dot-size);
        min-height: var(--dot-size);
        border-radius: var(--dot-radius);
        outline: var(--dot-outer-space) solid;
        border: var(--dot-border) solid;
        font-size: 16px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .e-timeline .e-opposite-content,
    .e-timeline .e-content {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 50%;
        flex: 1 1 50%;
    }

    .e-timeline .e-opposite-content {
        font-size: 12px;
    }

    .e-timeline .e-content {
        text-align: left;
        font-size: 14px;
        font-weight: 500;
    }

    .e-timeline .e-timeline-item:first-child::after {
        top: 0;
    }

    .e-timeline .e-timeline-item:last-child::after {
        bottom: calc(var(--dot-size) * 2);
    }

    .e-timeline.e-vertical.e-align-before .e-timeline-item, .e-timeline.e-vertical.e-align-alternate .e-timeline-item:nth-of-type(even), .e-timeline.e-vertical.e-align-alternatereverse .e-timeline-item:nth-of-type(odd) {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }

    .e-timeline.e-vertical.e-timeline-reverse .e-timeline-items {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }

    .e-timeline.e-vertical.e-align-before .e-timeline-items .e-opposite-content, .e-timeline.e-vertical.e-align-after .e-timeline-items .e-content {
        text-align: left;
    }

    .e-timeline.e-vertical .e-timeline-item.e-connector:last-child::after {
        content: unset;
    }

    .e-timeline.e-vertical.e-align-before.e-opposite-content-only .e-connector::after, .e-timeline.e-vertical.e-align-after.e-rtl.e-opposite-content-only .e-connector::after, .e-timeline.e-vertical.e-align-before.e-rtl.e-content-only .e-connector::after, .e-timeline.e-vertical.e-align-after.e-content-only .e-connector::after {
        left: calc(var(--dot-size) * 3 / 2 - var(--connector-size));
        right: auto;
    }

    .e-timeline.e-vertical.e-align-before.e-content-only .e-connector::after, .e-timeline.e-vertical.e-align-before.e-rtl.e-opposite-content-only .e-connector::after, .e-timeline.e-vertical.e-align-after.e-opposite-content-only .e-connector::after, .e-timeline.e-vertical.e-align-after.e-rtl.e-content-only .e-connector::after {
        right: calc(var(--dot-size) * 3 / 2 - var(--connector-size));
        left: auto;
    }

    .e-timeline.e-vertical.e-align-after.e-opposite-content-only .e-content, .e-timeline.e-vertical.e-align-after.e-content-only .e-opposite-content, .e-timeline.e-vertical.e-align-before.e-opposite-content-only .e-content, .e-timeline.e-vertical.e-align-before.e-content-only .e-opposite-content {
        -webkit-box-flex: 0;
        -ms-flex: 0;
        flex: 0;
    }

    .e-timeline.e-vertical.e-timeline-reverse .e-timeline-item.e-connector:first-child::after {
        content: unset;
    }

    .e-timeline.e-vertical.e-timeline-reverse .e-timeline-item.e-connector:last-child::after {
        content: "";
        bottom: 0;
    }

    .e-timeline.e-vertical.e-align-after .e-opposite-content, .e-timeline.e-vertical.e-align-before .e-content, .e-timeline.e-vertical.e-rtl.e-align-after .e-content, .e-timeline.e-vertical.e-rtl.e-align-alternate .e-timeline-item:nth-of-type(odd) .e-content, .e-timeline.e-vertical.e-rtl.e-align-alternatereverse .e-timeline-item:nth-of-type(even) .e-content, .e-timeline.e-vertical.e-align-alternate .e-timeline-item:nth-of-type(even) .e-content, .e-timeline.e-vertical.e-align-alternatereverse .e-timeline-item:nth-of-type(odd) .e-content, .e-timeline.e-vertical.e-rtl.e-align-alternate .e-timeline-item:nth-of-type(even) .e-opposite-content, .e-timeline.e-vertical.e-rtl.e-align-alternatereverse .e-timeline-item:nth-of-type(odd) .e-opposite-content, .e-timeline.e-vertical.e-rtl.e-align-before .e-opposite-content, .e-timeline.e-vertical.e-align-alternate .e-opposite-content, .e-timeline.e-vertical.e-align-alternatereverse .e-opposite-content {
        text-align: right;
    }

    .e-timeline.e-vertical.e-align-before .e-opposite-content, .e-timeline.e-vertical.e-align-after .e-content, .e-timeline.e-vertical.e-rtl.e-align-after .e-opposite-content, .e-timeline.e-vertical.e-align-alternate .e-timeline-item:nth-of-type(odd) .e-content, .e-timeline.e-vertical.e-align-alternatereverse .e-timeline-item:nth-of-type(even) .e-content, .e-timeline.e-vertical.e-align-alternate .e-timeline-item:nth-of-type(even) .e-opposite-content, .e-timeline.e-vertical.e-align-alternatereverse .e-timeline-item:nth-of-type(odd) .e-opposite-content, .e-timeline.e-vertical.e-rtl.e-align-alternate .e-timeline-item:nth-of-type(even) .e-content, .e-timeline.e-vertical.e-rtl.e-align-alternatereverse .e-timeline-item:nth-of-type(odd) .e-content, .e-timeline.e-vertical.e-rtl.e-align-before .e-content, .e-timeline.e-vertical.e-rtl.e-align-alternate .e-opposite-content, .e-timeline.e-vertical.e-rtl.e-align-alternatereverse .e-opposite-content {
        text-align: left;
    }

    .e-timeline.e-vertical .e-dot-item {
        width: calc(var(--dot-size) * 2);
    }

    .e-timeline.e-horizontal .e-timeline-items {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .e-timeline.e-horizontal .e-timeline-item {
        height: auto;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .e-timeline.e-horizontal.e-timeline-reverse.e-rtl .e-timeline-item.e-connector::after {
        left: calc(50% - var(--connector-size));
        right: auto;
    }

    .e-timeline.e-horizontal.e-rtl .e-timeline-item.e-connector::after, .e-timeline.e-horizontal.e-timeline-reverse .e-timeline-item.e-connector::after {
        right: calc(50% - var(--connector-size));
        left: auto;
    }

    .e-timeline.e-horizontal .e-dot-item {
        margin: calc(var(--dot-size) * 2 / 2) 0;
    }

    .e-timeline.e-horizontal .e-opposite-content,
    .e-timeline.e-horizontal .e-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .e-timeline.e-horizontal.e-align-alternate .e-timeline-item:nth-of-type(even) .e-content, .e-timeline.e-horizontal.e-align-alternate .e-timeline-item:nth-of-type(odd) .e-opposite-content, .e-timeline.e-horizontal.e-align-alternatereverse .e-timeline-item:nth-of-type(odd) .e-content, .e-timeline.e-horizontal.e-align-alternatereverse .e-timeline-item:nth-of-type(even) .e-opposite-content, .e-timeline.e-horizontal.e-align-before .e-content {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }

    .e-timeline.e-horizontal.e-align-alternate .e-timeline-item:nth-of-type(odd) .e-content, .e-timeline.e-horizontal.e-align-alternate .e-timeline-item:nth-of-type(even) .e-opposite-content, .e-timeline.e-horizontal.e-align-alternatereverse .e-timeline-item:nth-of-type(even) .e-content, .e-timeline.e-horizontal.e-align-alternatereverse .e-timeline-item:nth-of-type(odd) .e-opposite-content, .e-timeline.e-horizontal.e-align-before .e-opposite-content {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    .e-timeline.e-horizontal.e-align-before .e-timeline-item, .e-timeline.e-horizontal.e-align-alternate .e-timeline-item:nth-of-type(even), .e-timeline.e-horizontal.e-align-alternatereverse .e-timeline-item:nth-of-type(odd) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }

    .e-timeline.e-horizontal.e-timeline-reverse .e-timeline-items {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
    }

    .e-timeline.e-horizontal .e-timeline-item::after {
        width: 100%;
        height: 0;
        top: calc(50% - var(--connector-size));
    }

    .e-timeline.e-horizontal .e-opposite-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        text-align: left;
        padding: 0;
    }

    .e-timeline.e-horizontal .e-content {
        padding: 0;
    }

    .e-timeline.e-horizontal .e-timeline-item:last-child::after {
        width: auto;
    }

    .e-timeline .e-dot {
        background-color: #edebe9;
        border-color: #edebe9;
        color: #201f1e;
        outline-color: #fff;
    }

    .e-timeline.e-outline .e-dot {
        background-color: #fff;
    }

    .e-timeline .e-timeline-item.e-connector::after {
        border-color: #edebe9;
    }

    .e-timeline .e-content {
        color: #323130;
    }

    .e-timeline .e-opposite-content {
        color: #323130;
    }

    .e-timeline .e-item-disabled .e-content,
    .e-timeline .e-item-disabled .e-opposite-content {
        color: #a19f9d;
    }

.e-bigger.e-timeline [class^="e-dot "]::before,
.e-bigger .e-timeline [class^="e-dot "]::before {
    min-width: 40px;
    min-height: 40px;
    font-size: 18px;
}

.e-bigger.e-timeline .e-dot-item,
.e-bigger .e-timeline .e-dot-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 calc(var(--dot-size) * 4);
    flex: 0 1 calc(var(--dot-size) * 4);
}

.e-bigger.e-timeline.e-vertical.e-align-before.e-opposite-content-only .e-connector::after, .e-bigger.e-timeline.e-vertical.e-align-after.e-rtl.e-opposite-content-only .e-connector::after, .e-bigger.e-timeline.e-vertical.e-align-before.e-rtl.e-content-only .e-connector::after, .e-bigger.e-timeline.e-vertical.e-align-after.e-content-only .e-connector::after,
.e-bigger .e-timeline.e-vertical.e-align-before.e-opposite-content-only .e-connector::after,
.e-bigger .e-timeline.e-vertical.e-align-after.e-rtl.e-opposite-content-only .e-connector::after,
.e-bigger .e-timeline.e-vertical.e-align-before.e-rtl.e-content-only .e-connector::after,
.e-bigger .e-timeline.e-vertical.e-align-after.e-content-only .e-connector::after {
    left: calc(var(--dot-size) * 4 / 2 - var(--connector-size));
}

.e-bigger.e-timeline.e-vertical.e-align-before.e-content-only .e-connector::after, .e-bigger.e-timeline.e-vertical.e-align-before.e-rtl.e-opposite-content-only .e-connector::after, .e-bigger.e-timeline.e-vertical.e-align-after.e-opposite-content-only .e-connector::after, .e-bigger.e-timeline.e-vertical.e-align-after.e-rtl.e-content-only .e-connector::after,
.e-bigger .e-timeline.e-vertical.e-align-before.e-content-only .e-connector::after,
.e-bigger .e-timeline.e-vertical.e-align-before.e-rtl.e-opposite-content-only .e-connector::after,
.e-bigger .e-timeline.e-vertical.e-align-after.e-opposite-content-only .e-connector::after,
.e-bigger .e-timeline.e-vertical.e-align-after.e-rtl.e-content-only .e-connector::after {
    right: calc(var(--dot-size) * 4 / 2 - var(--connector-size));
}

.e-bigger.e-timeline .e-dot,
.e-bigger .e-timeline .e-dot {
    min-width: 20px;
    min-height: 20px;
}

.e-bigger.e-timeline .e-opposite-content,
.e-bigger .e-timeline .e-opposite-content {
    font-size: 14px;
}

.e-bigger.e-timeline .e-content,
.e-bigger .e-timeline .e-content {
    font-size: 16px;
    font-weight: 500;
}

.e-grid .e-gridheader {
    border-top-color: transparent;
}

.e-checkbox-wrapper .e-check,
.e-css.e-checkbox-wrapper .e-check {
    display: inline;
    justify-content: center;
    align-items: center; 
}

.e-checkbox-wrapper .e-frame, .e-css.e-checkbox-wrapper .e-frame {
    height: 15px;
    width: 15px;
}


.e-checkbox-wrapper .e-frame, .e-css.e-checkbox-wrapper .e-frame {
    border-color: gray;
}

.e-checkbox-wrapper .e-stop, .e-css.e-checkbox-wrapper .e-stop {
    font-size: 13px;
    line-height: 14px;
}

.e-grid .e-sortnumber {
    display: none;
}

.e-footer-content .e-btn.e-primary.e-flat:not([DISABLED]) {
    background: var(--theme-icon-color) !important;
    color: #fff !important;
}

.e-footer-content .e-btn.e-primary.e-flat:not([DISABLED]) {
    background: var(--theme-icon-color) !important;
    color: #fff !important;
}



.e-footer-content .e-btn.e-flat:not([DISABLED]) {
    background: #fff;
    border-color: #8a8886;
    color: #323130;
}

.e-footer-content .e-btn.e-flat:not([DISABLED]) {
    background: #fff;
    border-color: #8a8886;
    color: #323130;
}

.e-treeview .e-fullrow {
    height: 42px;
}

.e-tab .e-tab-header {
    height: 38px;
    min-height: 38px;
}

.e-grid .e-content {
    overflow-y: auto !important;
}

.e-btn .e-btn-icon.e-icon-left, .e-css.e-btn .e-btn-icon.e-icon-left {
    color: var(--theme-color-icon-folders) !important;
}

.e-menu-icon {
    color: var(--theme-color-icon-folders) !important;
}
.properties-container {
    display: flex;
    flex-direction: column;
    height: 86vh;
    background-color: #3f3f3f;
    justify-content: start !important;
    font-size: var(--theme-font);
}

.properties-sub-container {
    flex-grow: 1;
    overflow-y: auto;
}

.properties-row {
    display: flex;
    flex-direction: row;
    margin: 2px;
    padding-left: 5px;
}

.properties-label {
    width: 200px;
    color: white;
    margin: 2px;
    display: flex;
    align-items: center; /* This centers the text vertically */
    justify-content: start;
}

.properties-value {
    width: 100%;
    margin-top: 2px;
    margin-right: 5px;
    margin-bottom: 2px;
    margin-left: 2px;
    --theme-color: white;
    color: var(---theme-color);
}



.properties-button {
    background-color: #017e84;
    color: white !important;
}

.dynamic-input {
    background: #2e2e2e !important;
    border-color: #3f3f3f !important;
    color: var(--theme-color) !important;
}

.properties-value .e-input-group:not(.e-success):not(.e-warning):not(.e-error),
.properties-value .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
    border-color: #3f3f3f;
    background: #3f3f3f;
    color: var(--theme-color);
}

.properties-value .e-input-group .e-input-group-icon, .e-input-group.e-control-wrapper .e-input-group-icon {
    background-color: #2e2e2e !important;
    color: white;
}

.e-dropdownbase .e-list-item {
    background-color: #2e2e2e !important;
    color: white !important;
}

.e-dropdownbase .e-list-item:active {
    background-color: red !important;
    color: white !important;
}


.e-dropdownbase .e-list-item:hover {
    background-color: #694b61 !important;
}

.e-treeview > .e-ul {
    overflow-y: hidden;
}
.rounded-button{
    width:150px;
    height:40px;
    margin:5px;
}

.unauth-container{
    display:flex;
    flex-direction:row;
}

.unauth-button{
    margin:5px;
}

.account-info {
    width: 60%;
    padding: 10px;
    word-wrap: normal;
    font-size: 1rem !important;
    line-height: 1.6;
    color: #1976d2;
    margin-right: 16px;
    font-family: sans-serif;
    font-weight: 500;
    letter-spacing: .1rem;
    text-align: center;
}

.e-btn.e-outline:hover, .e-css.e-btn.e-outline:hover {
    background-color: #f3f2f1;
    border-color: #8a8886;
    box-shadow: none;
    color: #694b61 !important;
}

.e-btn.e-outline {
    border-radius: 7px;
    border-width: 1.5px;
    border-color: white !important;
    height: 35px;
    font-family: sans-serif;
    font-weight: 500;
    letter-spacing: .1rem;
    text-align: center;
    color: white !important;
    word-wrap: normal;
    font-size: 1rem !important;
    line-height: 1.6;
}



general
.e-dropdown-btn, .e-dropdown-btn.e-btn {
    color: var(--theme-font-color-mode) !important;
}

.e-dropdown-btn:hover {
    background-color: var(--theme-dropdown-button-hover) !important;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

.e-appbar.e-primary {
    background: #694b61;
    color: white !important;
    border-color: #694b61;
}

/* Hide text on small screens and show only icons */
@media (max-width: 767px) {
    .button-text {
        display: none;
    }
}

/* Show text on medium and larger screens */
@media (min-width: 768px) {
    .button-text {
        display: inline;
    }
}
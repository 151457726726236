.centered-div {
    height: 95vh;
    padding: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.editor-head {
    height: 55px;
}


.split-pages-title {
    text-align: center;
    margin: 10px;
    color: var(--theme-color);
    cursor: initial;
}

.toolbar-button {
    margin: 5px;
    border: none !important;
    background-color: transparent !important;
}

.main-dialog-header {
    background-color: #f8f9fa;
    border-bottom: solid;
    border-bottom-width: thin;
    border-bottom-color: #dee2e6;
    border-top: solid;
    border-top-width: thin;
    border-top-color: #dee2e6;
    margin: 5px;
}

.min-width-dialog .e-dlg-content {
    min-width: 600px;
}

#page-editor-dialog {
    min-width: 700px !important;
}



@import '../node_modules/@syncfusion/ej2-base/styles/fluent.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/fluent.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/fluent.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/fluent.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/fluent.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/fluent.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/fluent.css';
@import '../node_modules/@syncfusion/ej2-layouts/styles/fluent.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/fluent.css';
@import "../node_modules/@syncfusion/ej2-react-filemanager/styles/fluent.css";
@import "../node_modules/@syncfusion/ej2-react-documenteditor/styles/fluent.css";
@import '../node_modules/@syncfusion/ej2-react-spreadsheet/styles/fluent.css';
/*@import "/src/localstyles/bootstrap5/light/bootstrap5.css";
@import "/src/localstyles/icons/bootstrap5.css";*/

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.e-spinner-inner {
  visibility: hidden !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after{
    background:none;
}


@keyframes App-logo-spin {
    from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.file-manager-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.e-grid {
  width: 100%;
  height: 100%;
}

.e-grid .e-headercell,
.e-grid .e-rowcell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-grid .e-rowcell:last-child {
  text-align: right; /* Ensure 'Size' column aligns correctly */
}

.file-manager-layout .e-splitter {
  overflow: hidden;
}

.e-altrow {
    background-color: var(--theme-list-background-altrow) !important
}
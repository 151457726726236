
.e-row {
    height: initial;
}

tr {
    height: initial;
}

div.e-toolbar-item.e-menu-tab.e-template[data-id="tabitem_0"] {
    display: none;
}
#pdfViewerId_openContainer {
    visibility: hidden;
}

.ribbon {
    display: flex;
    flex-direction: row;
    background-color: white;
    height: 30px;
}

.ribbon-buttons {
    background-color: white !important;
    color: black !important;
    margin: 2px !important;
}

.rotate-up-icon {
    transform: rotateZ(270deg);
}

.rotate-down-icon {
    transform: rotateZ(270deg);
}

.pdf-page-thumbnail {
    display: flex;
    flex-direction: row;
    height: 220px;
}

.pdf-page-thumbnail-container {
    height: 220px;
    padding: 5px;
}

.pdf-page-thumbnail-page-content {
    border: 1px solid #c0c0c0;
    padding: 5px;
}

.pdf-page-thumbnail-content {
    display: flex;
    flex-direction: column;
}

.pdf-page-thumbnail-number {
    cursor: pointer;
    text-align: center;
}

.page-divider {
    width: 25px;
    height: 180px;
    margin-left: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cut-divider {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 10px;
}

.uncutted-page {
    border-right: dotted;
    border-right-width: 1px;
    height: 95px;
}

.uncutted-page:hover {
    border-right-width: 2px;
}

.cutted-page {
    border-right: solid;
    border-right-width: 1px;
    height: 40px;
}



.main-sidebar-component {
    background-color: transparent !important;
}

.page-previews {
    background-color: white;
    padding: 5px;
    overflow-y:auto
}

.secondary-split {
    width: 100%;
    height: inherit;
}
/*
.e-treeview .e-list-item.e-active > .e-text-content .e-list-text {
    color: black ;
}*/

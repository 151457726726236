:root {
    --theme-background-color-mode: white;
    --theme-font-color-mode: #1F2937;
    --theme-font-weight-normal: 400;
    --theme-dropdown-button-hover: #ebebeb;
    --theme-backgroundshading-color: #f9fafb;
    --theme-border-color: #e0e0e0;
    --theme-color: #212529;
    --theme-icon-color: #714B67;
    --theme-font: 14px;
    --theme-font-smaller: 12px;
    --theme-font-smallest: 10px;
    --theme-font-bigger: 15px;
    --theme-line-height: 40px;
    --theme-notification-bg-success: green;
    --theme-notification-bg-information: blue;
    --theme-notification-bg-error: red;
    --theme-notification-bg-warning: orange;
    --theme-color-active: #1F2937;
    --theme-color-icon-text-files: #6c757d; /* Gray for text files */
    --theme-color-icon-word-docs: #0078d7; /* Blue for Word documents */
    --theme-color-icon-pdf-files: #d32f2f; /* Red for PDFs */
    --theme-color-icon-spreadsheets: #388e3c; /* Green for Excel files */
    --theme-color-icon-presentations: #ff9800; /* Orange for PowerPoint */
    --theme-color-icon-images: #9c27b0; /* Purple for images */
    --theme-color-icon-videos: #2196f3; /* Blue for videos */
    --theme-color-icon-audio: #f44336; /* Red for audio */
    --theme-color-icon-archives: #ff5722; /* Deep orange for archives */
    --theme-color-icon-code: #3f51b5; /* Indigo for code files */
    --theme-color-icon-css: #264de4; /* Blue for CSS */
    --theme-color-icon-html: #e44d26; /* Red for HTML */
    --theme-color-icon-jsx-tsx: #61dafb; /* Light blue for JSX/TSX */
    --theme-color-icon-folders: #714B67;; /* Yellow for folders */
    --theme-color-icon-default: #9e9e9e; /* Gray for default */
    --theme-list-background-selected: #e8f4f4;
    --theme-list-background-altrow: #f9fafb;
    --theme-btn-bg-color-hover: #d6d9dc;
    --theme-btn-color-hover: black;
    --theme-btn-bg-color-active: #d6d9dc;
    --theme-indicator-bg-color-active: #017e84;
    --theme-btn-color-active: #6c757d;
    --theme-font-family-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, "Noto Sans", Arial, "Odoo Unicode Support Noto", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --theme-font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, "Noto Sans", Arial, "Odoo Unicode Support Noto", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --theme-font-family: var(--theme-font-family-sans-serif);
}

.e-icons {
    font-family: "e-icons";
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-transform: none;
}

button.e-tbar-btn:hover .e-btn-icon {
    color: var(--theme-btn-color-active) !important;
}


body {
    margin: 0;
    font-family: var(--theme-font-family) !important;
    font-size: var(--theme-font) !important;
    color: var(--theme-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
}

div {
    font-family: var(--theme-font-family);
}

.secondary {
    background-color: var(--theme-backgroundshading-color);
}

.e-toolbar .e-toolbar-item .e-tbar-btn {
    min-height: 30px;
}

.e-dialog .e-footer-content {
    padding: 2px !important;
}

/*.e-dlg-header-content {
    background-color: #f4f6fc !important;
}*/

.e-dlg-header {
    font-size: var(--theme-font) !important;
}

.e-filemanager .e-toolbar {
    border-color: transparent;
    border-style: none;
    border-width: 1px;
}

.e-filemanager .e-large-icons .e-list-text {
    font-size: var(--theme-font) !important;
}

.e-input-group, .e-control-wrapper, .e-ddl, .e-lib, .e-keyboard {
    font-size: var(--theme-font) !important;
    color: var(--theme-color) !important;
    font-family: var(--theme-font-family) !important;
}

.e-treeview .e-list-text {
    font-size: var(--theme-font) !important;
    color: var(--theme-color) !important;
    font-family: var(--theme-font-family) !important;
}

.e-filemanager .e-grid .e-content .e-rowcell .e-fe-text {
    font-size: var(--theme-font) !important;
    font-family: var(--theme-font-family) !important;
}

.e-filemanager .e-address .e-list-text {
    color: var(--theme-color);
    font-family: var(--theme-font-family) !important;
}

.e-filemanager .e-large-icons .e-large-icon.e-active {
    --theme-color: #fff;
    color: var(--theme-color) !important;
    font-family: var(--theme-font-family) !important;
    background: var(--theme-list-background-selected) !important;
    line-height: 30px;
}

.e-filemanager .e-grid .e-gridheader .e-headercell .e-headertext {
    font-size: var(--theme-font-smallest) !important;
}

.e-filemanager .e-large-icons .e-large-icon {
    line-height: 30px;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
    color: var(--theme-color) !important;
    font-family: var(--theme-font-family) !important;
}


.ribbon-buttons {
    color: var(--theme-color) !important;
    font-family: var(--theme-font-family) !important;
}

.e-filemanager .e-grid .e-content .e-rowcell {
    color: var(--theme-color) !important;
    font-family: var(--theme-font-family) !important;
    font-size: var(--theme-font-smaller) !important;
}

.e-headertext {
    color: var(--theme-color) !important;
    font-family: var(--theme-font-family) !important;
}

.e-list-text {
    color: var(--theme-color);
    font-family: var(--theme-font-family) !important;
    font-size: var(--theme-font) !important;
}

.e-addressbar-ul .e-address-list-item .e-list-text {
    color: #017e84 !important;
    font-weight: 600 !important;
}

.e-gridheader {
    padding-right: 0px !important;
}

.e-dlg-content {
    padding: 2px !important;
}

.e-grid .e-gridheader .e-sortfilter .e-headercelldiv, .e-grid .e-gridheader .e-stackedheadercelldiv {
    color: var(--theme-color) !important;
    font-family: var(--theme-font-family) !important;
}

.e-treeview .e-list-item.e-active > .e-text-content, .e-treeview .e-list-item.e-active > .e-text-content .e-icon-collapsible, .e-treeview .e-list-item.e-active > .e-text-content .e-icon-expandable, .e-treeview .e-list-item.e-active > .e-text-content .e-list-text {
    color: var(--theme-color-active) !important;
}

.e-filemanager .e-grid td.e-active.e-rowcell:not(.e-editedbatchcell):not(.e-updatedtd) {
    color: var(--theme-color-active) !important;
    background: var(--theme-list-background-selected) !important;
}

.e-treeview .e-list-item.e-active > .e-fullrow {
    background: var(--theme-list-background-selected) !important;
}

.e-grid td.e-active {
    color: var(--theme-color-active) !important;
    font-family: var(--theme-font-family) !important;
    background-color: var(--theme-list-background-selected) !important
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn:hover .e-tbar-btn-text {
    color: var(--theme-color-active) !important;
}

.e-toolbar .e-tbar-btn:active .e-tbar-btn-text {
    color: var(--theme-btn-color-active) !important;
}


.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn:focus .e-tbar-btn-text {
    color: var(--theme-color-active) !important;
}

.radio-button:active {
    color: var(--theme-color-active) !important;
}

.e-btn-group .e-btn:active, .e-btn-group input:active + label.e-btn, .e-btn-group input:checked + label.e-btn, .e-css.e-btn-group .e-btn:active, .e-css.e-btn-group input:active + label.e-btn, .e-css.e-btn-group input:checked + label.e-btn {
    color: var(--theme-color-active) !important;
    background-color: var(--theme-btn-bg-color-active) !important;
    color: var(--theme-btn-color-active) !important;
}


.general-text {
    font-family: var(--theme-font-family) !important;
    font-size: var(--theme-font);
    color: var(--theme-color);
}

.e-dlg-header {
    font-family: var(--theme-font-family) !important;
    font-size: var(--theme-font);
    color: var(--theme-color) !important;
}

.e-list-item {
    font-family: var(--theme-font-family) !important;
    font-size: var(--theme-font);
    color: var(--theme-color) !important;
}

.e-listbox-wrapper:not(.e-list-template) .e-list-item, .e-listbox-wrapper .e-list-nrt, .e-listbox-wrapper .e-selectall-parent, .e-listbox-container:not(.e-list-template) .e-list-item, .e-listbox-container .e-list-nrt, .e-listbox-container .e-selectall-parent {
    height: 40px !important;
    line-height: var(--theme-line-height) !important;
    padding: 0px !important;
    padding-left: 5px !important;
    position: relative;
}

.e-listbox-wrapper .e-list-item.e-selected, .e-listbox-container .e-list-item.e-selected {
    background-color: var(--theme-list-background-selected);
    color: var(--theme-color-active) !important;
}

.e-toolbar .e-tbar-btn:hover {
    color: var(--theme-btn-color-hover) !important;
    background-color: var(--theme-btn-bg-color-hover) !important;
}

.e-toolbar .e-tbar-btn {
    font-family: var(--theme-font-family) !important;
    font-size: var(--theme-font-bigger) !important;
}

.e-btn-icon {
    color: #6c757d !important;
}

    .e-btn-icon :hover {
        color: var(--theme-btn-color-hover) !important;
    }

.e-btn-primary-icon {
    color: #6c757d !important;
}

    .e-btn-primary-icon :hover {
        color: var(--theme-btn-color-hover) !important;
    }

.toolbar-button {
    margin: 5px;
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
}


    .toolbar-button:hover {
        color: var(--theme-btn-color-hover) !important;
        background-color: var(--theme-btn-bg-color-hover) !important;
    }


.e-bigger {
    font-size: x-large;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.e-toolbar.e-control[class*="e-toolbar"] {
    height: 40px;
}

.e-tab .e-tab-header .e-toolbar-items {
    min-height: 20px;
}

.e-tab .e-tab-header .e-toolbar-item:not(.e-separator) {
    height: 20px;
    min-height: 30px;
}

.e-list-item {
    line-height: var(--theme-line-height);
}


span.e-fe-size {
    text-align: right !important;
    display: grid;
}

.e-fullrow {
    height: var(--theme-line-height);
    min-height: 10px;
}

.e-filemanager .e-large-icons .e-text-content {
    display: flex !important;
    flex-direction: column;
    height: 82px !important;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
    background: none;
}

.e-card .e-card-content {
    padding: 0px;
}


.e-toolbar .e-toolbar-items {
    height: 40px;
    background-color: var(--theme-background-color-mode);
}

/*Toast*/


.e-toast-container {
    z-index: 10000 !important;
}

    /* Remove potential spacing issues in the child elements */
    .e-toast-container .e-toast-template,
    .e-toast-container .e-toast-content,
    .e-toast-container * {
        margin: 0 !important;
    }

/*Icons*/
.e-fe-folder {
    filter: invert(28%) sepia(34%) saturate(414%) hue-rotate(293deg) brightness(119%) contrast(31%) !important;
}

.e-chevron-up-double:before {
    content: '\e822';
}

.e-chevron-down-double:before {
    content: '\e7fe';
}

.e-rotate-up:before {
    content: '\e713';
    display: inline-block;
    transform: rotate(90deg);
}

.e-rotate-down:before {
    content: '\e755';
    display: inline-block;
    transform: rotate(90deg);
}

.e-action-undo:before {
    content: '\e755';
}

.e-action-redo:before {
    content: '\e713';
}


.e-rotate-right:before {
    content: '\e8a6';
}

.e-rotate-left:before {
    content: '\e8a5';
}


.e-delete:before {
    content: '\e820';
}

.e-close-dialog:before {
    content: '\e7e7';
}

.e-cancel-action:before {
    content: '\e8cf';
}

.e-user-defined:before {
    content: '\e709';
}

.e-layers:before {
    content: '\e8f9';
}

.e-ok-check-mark:before {
    content: '\e774'
}

.e-save-changes:before {
    content: '\e7c8';
}

.e-break-page:before {
    content: '\e742';
}

.e-merge-pdf:before {
    content: '\e700';
}

.e-edit-document:before {
    content: '\e730';
}

.e-split-pdf:before {
    content: '\e742';
}

.e-refresh:before {
    content: '\e772'
}

.e-maximize:before {
    content: '\e81c'
}

.e-minimize:before {
    content: '\e7b0'
}

.e-zoom-in:before {
    content: '\e349'
}

.e-zoom-out:before {
    content: '\e349'
}

.e-expand:before {
    content: '\e7c9'
}

.e-collapse:before {
    content: '\e80f'
}

.e-add-attachment:before {
    content: '\e768'
}

.e-preview:before {
    content: '\e754'
}

.e-odoo-menu:before {
    content: '\e708';
}

.e-treeview .e-list-item div.e-icons::before {
    content: "\e83c";
}

.e-treeview .e-list-item {
    padding: 1px 0;
}


.top-ribbon-button {
    background-color: transparent;
}


.e-btn, .e-css.e-btn {
    font-weight: normal !important;
}

.div-toolbar-button {
    background-color: transparent;
    border-color: transparent;
    border-radius: 2px;
    bottom: 0;
    float: right;
    height: 32px;
    left: 0;
    position: relative;
    width: 32px;
    padding: 4px 9px;
    cursor: pointer;
}

    .div-toolbar-button:hover {
        background-color: #f3f2f1;
        border-color: transparent;
        box-shadow: 0 0 0 transparent;
    }


.e-pv-viewer-container {
    background-color: #f3f2f1;
}

.default-splitter {
    background-color: #f3f2f1;
    --default-splitter-line-size: 2px !important;
}

.e-popup-btn {
    border-color: #f3f2f1;
}

    .e-popup-btn:hover {
        background-color: #87607c !important;
    }


.e-filemanager .e-address {
    height: 46px !important;
}

.e-dropdown-popup ul {
    max-height: 600px;
    overflow-y: auto;
}


.e-filemanager .e-treeview .e-list-parent .e-list-item .e-list-text {
    line-height: 0px !important;
}

.e-grid .e-rowcell {
    line-height: 0px !important;
    height: var(--theme-line-height) !important;
}



.e-filemanager .e-grid td {
    padding: 0px !important;
}


.e-dropdown-popup ul .e-item.e-separator {
    height: 5px !important;
}

.e-grid .e-gridcontent {
    padding-right: 0px;
}

.e-filemanager .e-grid .e-content {
    padding-right: 10px;
}
/*Color Updates*/
.e-tab .e-tab-header .e-toolbar-items .e-toolbar-item.e-active::before {
    background-color: var(--theme-indicator-bg-color-active);
}

.e-checkbox-wrapper .e-frame.e-check, .e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: var(--theme-indicator-bg-color-active);
    border-color: var(--theme-indicator-bg-color-active);
    color: #fff;
}

.e-checkbox-wrapper .e-frame.e-stop, .e-css.e-checkbox-wrapper .e-frame.e-stop {
    background-color: #fff;
    border-color: var(--theme-indicator-bg-color-active);
    color: var(--theme-indicator-bg-color-active);
}


.e-input:focus:not(.e-success):not(.e-warning):not(.e-error),
.e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
.e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
.e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) input:focus,
.e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group) textarea:focus,
.e-float-input:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input,
.e-float-input.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-input-group).e-input-focus input {
    border-color: var(--theme-indicator-bg-color-active);
    border-radius: 2px;
    -webkit-box-shadow: 0 0 0 1px var(--theme-indicator-bg-color-active);
    box-shadow: 0 0 0 1px var(--theme-indicator-bg-color-active);
}

.e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error),
.e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
    border-color: var(--theme-indicator-bg-color-active);
    border-color: var(--theme-indicator-bg-color-active);
    border-radius: 2px;
    -webkit-box-shadow: 0 0 0 1px var(--theme-indicator-bg-color-active);
    box-shadow: 0 0 0 1px var(--theme-indicator-bg-color-active);
}

.e-input-group.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-input-group.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-float-input.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
.e-float-input.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
.e-float-input.e-control-wrapper.e-input-focus:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]) {
    border-color: var(--theme-indicator-bg-color-active);
}

.e-input-group:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-input-group.e-control-wrapper:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-float-input:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-float-input.e-control-wrapper:active:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-float-input:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
.e-float-input.e-control-wrapper:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
.e-float-input:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
.e-float-input.e-control-wrapper:active:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]) {
    border-color: var(--theme-indicator-bg-color-active);
}


.e-control-wrapper.e-slider-container .e-slider .e-range.e-tab-range {
    background-color: var(--theme-indicator-bg-color-active);
}

.e-control-wrapper.e-slider-container.e-slider-hover .e-range {
    background-color: var(--theme-indicator-bg-color-active);
}

.e-control-wrapper.e-slider-container.e-slider-hover .e-handle {
    border-color: var(--theme-indicator-bg-color-active);
}

.e-control-wrapper.e-slider-container:not(.e-disabled):not(.e-read-only):active .e-handle {
    border-color: var(--theme-indicator-bg-color-active);
}

.e-control-wrapper.e-slider-container:not(.e-disabled):not(.e-read-only):active .e-range {
    background-color: var(--theme-indicator-bg-color-active);
}

.e-upload .e-upload-files .e-upload-file-list .e-file-container .e-progress-inner-wrap .e-upload-progress-bar.e-upload-progress {
    background: var(--theme-indicator-bg-color-active);
}

.e-container.e-color-palette .e-clr-pal-rec-wpr .e-recent-clr-span {
    color: var(--theme-indicator-bg-color-active);
}

.e-de-ctnr-properties-pane {
    height: calc(100% - 60px);
}

.e-treeview .e-list-icon, .e-treeview .e-list-img {
    font-size: var(--theme-font-bigger);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.e-treeview > .e-ul {
    overflow: hidden;
}

.default-button-style {
    background-color: transparent;
    border: 1px solid;
    border-radius: 2px;
    border-color: var(--theme-border-color);
    color: var(--theme-color);
    font-size: var(--theme-font);
    font-weight: var(--theme-font-weight-normal);
    height: 30px;
}

.e-splitter.e-splitter-horizontal .e-pane.e-pane-horizontal, .e-splitter.e-splitter-vertical .e-pane.e-pane-horizontal {
    overflow: hidden;
}

.fa {
    color: var(--theme-icon-color) ;
}

/* Text and document files */
.fa-file-text {
    color: var(--theme-color-icon-text-files);
}

.fa-file-word-o {
    color: var(--theme-color-icon-word-docs);
}

.fa-file-pdf-o {
    color: var(--theme-color-icon-pdf-files);
}

.fa-file-excel-o {
    color: var(--theme-color-icon-spreadsheets);
}

.fa-file-powerpoint-o {
    color: var(--theme-color-icon-presentations);
}

/* Image files */
.fa-picture-o {
    color: var(--theme-color-icon-images);
}

/* Video files */
.fa-file-video {
    color: var(--theme-color-icon-videos);
}

/* Audio files */
.fa-music {
    color: var(--theme-color-icon-audio);
}

/* Archive files */
.fa-file-archive-o {
    color: var(--theme-color-icon-archives);
}

/* Code files */
.fa-code {
    color: var(--theme-color-icon-code);
}

.fa-css3 {
    color: var(--theme-color-icon-css);
}

.fa-html5 {
    color: var(--theme-color-icon-html);
}

.fa-jsfiddle {
    color: var(--theme-color-icon-jsx-tsx);
}

/* Folder */
.fa-folder {
    color: var(--theme-color-icon-folders);
}

/* Default */
.fa-file-o {
    color: var(--theme-color-icon-default);
}

/*.e-treeview .e-fullrow {
    width: calc(100% - 15px) !important;
}*/
/*.e-input-group input.e-input, .e-input-group textarea.e-input, .e-input-group.e-control-wrapper input.e-input, .e-input-group.e-control-wrapper textarea.e-input, .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input, .e-input-group.e-control-wrapper.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:focus, .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input, .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input:focus{
    background-color:green;
}*/

.toast-border-success.e-toast-container {
    border-left: 5px solid var(--theme-notification-bg-success);
    border-top: 1px solid var(--theme-notification-bg-success);
    border-bottom: 1px solid var(--theme-notification-bg-success);
}

/* Error Toast */
.toast-border-error.e-toast-container {
    border-left: 5px solid var(--theme-notification-bg-error);
    border-top: 1px solid var(--theme-notification-bg-error);
    border-bottom: 1px solid var(--theme-notification-bg-error);
}

/* Warning Toast */
.toast-border-warning.e-toast-container {
    border-left: 5px solid var(--theme-notification-bg-warning);
    border-top: 1px solid var(--theme-notification-bg-warning);
    border-bottom: 1px solid var(--theme-notification-bg-warning);
}

/* Information Toast */
.toast-border-info.e-toast-container {
   border-left: 5px solid var(--theme-notification-bg-success);
    border-top: 1px solid var(--theme-notification-bg-success);
    border-bottom: 1px solid var(--theme-notification-bg-success);
}

.main {
    background-color: whitesmoke;
    width: 100%;
    position: absolute;
    height: calc(100% - 60px);
}

.centered {
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.centered-text {
    width: 60%;
    padding: 10px;
    word-wrap: normal;
    font-size: 1.25rem;
    line-height: 1.6;
    color: #1976d2;
    margin-right: 16px;
    font-family: sans-serif;
    font-weight: 500;
    text-align:center;
}

.e-dropdown-popup ul .e-item {
    height: 30px !important;
    line-height: 30px !important;
}